import { Typography } from "antd"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { Button } from "components/shared/Button"
import { ART_WORK_SEARCH_KEY } from "constants/search"
import colors from "helpers/colors"
import { useClassifications, useMaxPrice, useTechniques } from "hooks/static"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import { Dispatch, useCallback, useEffect, useMemo, useState, VFC } from "react"
import styled from "styled-components"
import { queryStringParams } from "utils/queryStringParams"

import { ArtWorkFilterFormItem } from "./ArtWorkFilterFormItem"
import { ArtWorkFilterFormItemPrice } from "./ArtWorkFilterFormItemPrice"
import { StyledSearchInput } from "./StyledSearchInput"

type Props = {
  setIsFilterVisible?: Dispatch<boolean>
  xs?: boolean
  artists?: ArtistNameType[]
}

export const ArtWorkFilterForm: VFC<Props> = ({ xs, setIsFilterVisible, artists }) => {
  const router = useRouter()
  const { t, i18n } = useTranslation()
  const language = i18n.language

  const { data: classifications } = useClassifications()
  const { data: techniques } = useTechniques()
  const { data: maxPrice } = useMaxPrice()

  const hash = router.asPath.match(/#.*$/)
  const path = router.asPath.replace(/#.*$/, "").split(/\?/)[0]
  const firstParams = new URLSearchParams(router.asPath.replace(/#.*$/, "").split(/\?/)[1])

  const [searchText, setSearchText] = useState<string>(firstParams.get(ART_WORK_SEARCH_KEY.keyword) as string || "")
  const [checkedArtistIds, setCheckedArtistIds] = useState<CheckboxValueType[]>(firstParams.getAll(ART_WORK_SEARCH_KEY.artist) as string[] || [])
  const [checkedClassificationIds, setCheckedClassificationIds] = useState<CheckboxValueType[]>(firstParams.getAll(ART_WORK_SEARCH_KEY.classification) as string[] || [])
  const [checkedTechniqueIds, setCheckedTechniqueIds] = useState<CheckboxValueType[]>(firstParams.getAll(ART_WORK_SEARCH_KEY.technique) as string[] || [])
  const [minPriceValue, setMinPriceValue] = useState<number | undefined>(firstParams.get(ART_WORK_SEARCH_KEY.minPrice) ? Number(firstParams.get(ART_WORK_SEARCH_KEY.minPrice) as string) : undefined)
  const [maxPriceValue, setMaxPriceValue] = useState<number | undefined>(firstParams.get(ART_WORK_SEARCH_KEY.maxPrice) ? Number(firstParams.get(ART_WORK_SEARCH_KEY.maxPrice) as string) : undefined )

  const queryObject = useMemo(() => ({
    [ART_WORK_SEARCH_KEY.keyword]: searchText,
    [ART_WORK_SEARCH_KEY.artist]: checkedArtistIds,
    [ART_WORK_SEARCH_KEY.classification]: checkedClassificationIds,
    [ART_WORK_SEARCH_KEY.technique]: checkedTechniqueIds,
    [ART_WORK_SEARCH_KEY.minPrice]: minPriceValue?.toString(),
    [ART_WORK_SEARCH_KEY.maxPrice]: maxPriceValue?.toString()
  }), [checkedArtistIds, checkedClassificationIds, checkedTechniqueIds, maxPriceValue, minPriceValue, searchText])

  const queryString = useMemo(() => queryStringParams(queryObject), [queryObject])

  const clearValuesCallback = useCallback(() => {
    setSearchText("")
    setCheckedArtistIds([])
    setCheckedClassificationIds([])
    setCheckedTechniqueIds([])
    setMinPriceValue(undefined)
    setMaxPriceValue(undefined)
  }, [])

  const setValuesCallback = useCallback(() => {
    firstParams.has("sort_field") && queryString.set("sort_field", firstParams.get("sort_field") || "")
    firstParams.has("sort_direction") && queryString.set("sort_direction", firstParams.get("sort_direction") || "")

    const newUrl = `${path}?${decodeURIComponent(queryString?.toString())}${hash}`

    if (router.asPath !== newUrl) {
      router.push(newUrl, undefined, { shallow: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString])

  useEffect(() => {
    /* PCではリアルタイムで結果をクエリに送信する */
    if (!xs) {
      setValuesCallback()
    }
  }, [setValuesCallback, xs])

  const handleClickSetButton = () => {
    setValuesCallback()
    setIsFilterVisible && setIsFilterVisible(false)
  }

  const handleClickCancelButton = () => {
    setIsFilterVisible && setIsFilterVisible(false)
  }

  return (
    <div id={"filter-top"} style={{ display: "flex", flexDirection: "column", minWidth: xs ? "100%" : "262px" }} >
      {xs &&
        <FixedWrapper style={{
          position: "sticky",
          top: 0,
          justifyContent: "space-between",
          borderBottom: `1px solid ${colors.grey}`
        }}>
          <a onClick={handleClickCancelButton} style={{ textDecorationLine: "underline" }}>{t("キャンセル")}</a>
          <Button size="sm" type="secondary" onClick={clearValuesCallback} style={{ padding: "12px 16px" }}>{t("条件をクリア")}</Button>
        </FixedWrapper>
      }

      {!xs &&
        <Typography.Text style={{ fontSize: "16px", padding: "16px 0px", lineHeight: 1 }} strong>
          {t("フィルター")}
        </Typography.Text>
      }
      <div style={{ display: "flex", flexDirection: "column", padding: xs ? "40px 16px" : 0 }} >
        <div style={{ padding: "28px 0" }}>
          <Typography.Text style={{ display: "flex", fontSize: "16px", lineHeight: 1, paddingBottom: "20px" }}>{t("キーワード")}</Typography.Text>
          {xs ? (
            <StyledSearchInput
              defaultValue={searchText}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={t("検索ワードを入力")}
              allowClear
            />
          ) : (
            <StyledSearchInput
              onSearch={setSearchText}
              defaultValue={searchText}
              placeholder={t("検索ワードを入力")}
              allowClear
            />
          )}
        </div>
        {artists && <ArtWorkFilterFormItem title={t("アーティスト")} language={language} selectableObjects={artists} checkedIds={checkedArtistIds} setCheckedIds={setCheckedArtistIds} />}
        {classifications && <ArtWorkFilterFormItem title={t("分類")} language={language} selectableObjects={classifications} checkedIds={checkedClassificationIds} setCheckedIds={setCheckedClassificationIds} />}
        {techniques && <ArtWorkFilterFormItem title={t("技法")} language={language} selectableObjects={techniques} checkedIds={checkedTechniqueIds} setCheckedIds={setCheckedTechniqueIds} searchable/>}
        <ArtWorkFilterFormItemPrice
          maxLimit={maxPrice || 10000000}
          min={minPriceValue} max={maxPriceValue}
          setMin={setMinPriceValue} setMax={setMaxPriceValue} />
        <div style={{ paddingBottom: "60px" }} />
      </div>
      {xs &&
        <FixedWrapper style={{
          position: "fixed",
          bottom: 0,
          justifyContent: "center",
        }}>
          <Button size="md" type="primary" onClick={handleClickSetButton} style={{ display: "flex", width: "100%", padding: "16px 24px", fontSize: "16px", fontWeight: 400 }}>
            {t("結果を表示する")}
          </Button>
        </FixedWrapper>
      }
    </div>
  )
}

const FixedWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  padding: 16px;
  z-index: 5;
  width: 100vw;
`
